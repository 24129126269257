import React, { useEffect } from 'react';
import { navigate } from '@reach/router';

import { css, StyleSheet } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { RoundedButton } from '@lumoslabs/react_lux';
import PropTypes from 'prop-types';

import Layout from 'components/layout';
import i18n from 'utils/i18n';
import Metatags from 'components/Metatags';
import SubNav from '../subNav';

import Banner from './Banner';
import CompanyStats from './CompanyStats';
import Values from './Values';

const styles = StyleSheet.create({
  bottomSection: {
    margin: '40px 0 100px',
    textAlign: 'center'
  }
});

export default function JobsIndex({ location, pageContext }) {
  const { locale, pagePath } = pageContext;

  useEffect(() => {
    if (locale === 'en') {
      navigate('https://www.lumoslabs.com/careers');
    }
  }, [locale]);

  return (
    <Layout location={location} locale={pageContext.locale}>
      <Metatags
        title={i18n.t('jobs.title')}
        description={i18n.t('jobs.description')}
        type="article"
        canonical={pagePath}
        locale={locale}
      />
      <SubNav locale={locale} />
      <Banner />
      <CompanyStats />
      <Values />
      <Container>
        <Row className={css(styles.bottomSection)}>
          <Col>
            <h2>{i18n.t('jobs.finalHeader')}</h2>
            <RoundedButton value={i18n.t('jobs.cta')} href="//jobs.lever.co/lumosity" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

JobsIndex.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
};
