import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'utils/i18n';
import SubNav from './sub-nav/index';

const linksData = [
  { key: 'aboutUs', urlPath: 'about' },
  { key: 'resources', urlPath: 'resources' },
  { key: 'jobs', urlPath: 'jobs' }
];

function LumosLabsSubNav({ locale }) {
  const lumosLabsLinks = linksData.map((linkData) => ({
    name: i18n.t(`LumosLabs.subNav.${linkData.key}`),
    url: `/${locale}/${linkData.urlPath}`
  }));

  if (locale === 'en') {
    return <SubNav title="About Lumos Labs" />;
  }
  return <SubNav title="Lumos Labs" links={lumosLabsLinks} />;
}

LumosLabsSubNav.propTypes = {
  locale: PropTypes.string.isRequired
};

export default LumosLabsSubNav;
