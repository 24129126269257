import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import { RoundedButton, fonts } from '@lumoslabs/react_lux';

import Hero from 'components/ui/Hero';
import * as layout from 'styles/helpers/layout';
import i18n from '../../utils/i18n';

const styles = StyleSheet.create({
  header: fonts.customFontScaling(fonts.h2, {
    [layout.mediaQuery.maxWidth.tablet]: {
      fontSize: '1.6em'
    },
    [layout.mediaQuery.maxWidth.desktopLarge]: {
      fontSize: '2.5em'
    },
    marginBottom: 30
  }),
  heightAdjustment: {
    height: 545
  }
});

const query = graphql`
  query {
    file(absolutePath: { regex: "/images/jobs/banner/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default function Banner() {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <Hero
          fluidImage={data.file.childImageSharp.fluid}
          imageAlt={i18n.t('jobs.subNav.title')}
          styles={styles.heightAdjustment}
        >
          <h2 className={css(styles.header)}>{i18n.t('jobs.banner.header')}</h2>
          <RoundedButton value={i18n.t('jobs.cta')} href="//jobs.lever.co/lumosity" />
        </Hero>
      )}
    />
  );
}
