import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';

import * as layout from 'styles/helpers/layout';

const defaultStyles = StyleSheet.create({
  banner: {
    position: 'relative',
    height: 436,
    padding: 0,
    [layout.mediaQuery.maxWidth.tablet]: {
      height: 300
    }
  },
  bannerImage: {
    height: '100%',
    width: '100%'
  },
  contentWrapper: {
    ...layout.centerVertical,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    padding: '0 20px',
    top: 0,
    left: 0
  }
});

function Hero({ fluidImage, imageAlt, children, styles }) {
  return (
    <Container fluid className={css(defaultStyles.banner, styles)}>
      <Img fluid={fluidImage} className={css(defaultStyles.bannerImage)} alt={imageAlt} />
      <Row className={css(defaultStyles.contentWrapper)}>
        <Col>{children}</Col>
      </Row>
    </Container>
  );
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  fluidImage: PropTypes.object.isRequired,
  imageAlt: PropTypes.string.isRequired,
  /** Aphrodite css overrides */
  styles: PropTypes.arrayOf(PropTypes.object)
};

Hero.defaultProps = {
  styles: []
};

export default Hero;
