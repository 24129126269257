import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { css, StyleSheet } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image'

import i18n from '../../utils/i18n'
import * as layout from '../../styles/helpers/layout';

const valuesOrder = [
  { i18nKey: 'exceedExpectations'  },
  { i18nKey: 'teachLearn' },
  { i18nKey: 'beFearless' },
]

const styles = StyleSheet.create({
  values: {
    paddingTop: 30
  },
  header: {
    fontSize: '1.9em'
  },
  value: {
    marginTop: 50
  },
  valueHeader: {
    marginTop: 0,
    fontSize: '1.4em',
    lineHeight: '1em'
  },
  image: {
    [layout.mediaQuery.maxWidth.tabletLarge]: {
      width: 399,
      margin: '0 auto'
    },
    [layout.mediaQuery.maxWidth.tablet]: {
      width: '100%'
    }
  },
})

const query = graphql`
  query {
    allFile(filter: { absolutePath: { regex: "/images/jobs/values/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 445, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default class Values extends React.Component {
  static headers() {
    return i18n.t('jobs.values.headers', { returnObjects: true }).map(({ header, subheader }) => (
      <Row key={header}>
        <Col>
          <h2>{header}</h2>
          <p>{subheader}</p>
        </Col>
      </Row>
    ))
  }

  static textForValue(i18nKey) {
    const bullets = i18n.t(`jobs.values.${i18nKey}.blurbs`, { returnObjects: true }).map(blurb => (
      <li key={blurb} >
        { ReactHtmlParser(blurb) }
      </li>
    ))

    return (
      <div>
        <h3 className={css(styles.valueHeader)}>{i18n.t(`jobs.values.${i18nKey}.header`)}</h3>
        <p>{i18n.t(`jobs.values.${i18nKey}.subheader`)}</p>
        <ul>{bullets}</ul>
      </div>
    )
  }

  static values(images) {
    return valuesOrder.map(({ i18nKey }, i) => (
      <Row key={i18nKey} className={css(styles.value)}>
        <Col lg={7}>
          {Values.textForValue(i18nKey)}
        </Col>
        <Col lg={5}>
          <Image fluid={images[i].node.childImageSharp.fluid} alt="" className={css(styles.image)} />
        </Col>
      </Row>
    ));
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={data => (
          <Container className={css(styles.values)}>
            {Values.headers()}
            {Values.values(data.allFile.edges)}
          </Container>
        )}
      />
    )
  }
}
