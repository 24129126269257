import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { grayLighter } from '@lumoslabs/react_lux';

import foundedImg from './images/founded.png';
import employeesImg from './images/employees.png';
import membersImg from './images/members.png';
import i18n from '../../utils/i18n';

const factOrder = [
  { i18nKey: 'founded', image: foundedImg, height: 55, width: 55, marginTop: 0 },
  { i18nKey: 'employees', image: employeesImg, height: 40, width: 95, marginTop: 15 },
  { i18nKey: 'members', image: membersImg, height: 38, width: 44, marginTop: 17 }
]

const styles = StyleSheet.create({
  section: {
    padding: `20px 0 40px`,
    backgroundColor: grayLighter
  },
  image: {
    margin: '0 auto'
  },
  centered: {
    textAlign: 'center'
  }
})

export default class Banner extends React.Component {

  static companyFacts() {
    return factOrder.map(({ i18nKey, image, height, width, marginTop }) => (
      <Col key={i18nKey}>
        <div style={{ marginTop }}>
          <img src={image} style={{ width, height }} className={css(styles.image)} alt="Company Fact" />
          <p>{i18n.t(`jobs.stats.${i18nKey}`)}</p>
        </div>
      </Col>
    ));
  }

  render() {
    return (
      <section className={css(styles.section)}>
        <Container>
          <Row className={css(styles.centered)}>
            <Col>
              <h2>{i18n.t('jobs.stats.header')}</h2>
            </Col>
          </Row>
          <Row className={css(styles.centered)}>
            {Banner.companyFacts()}
          </Row>
        </Container>
      </section>
    )
  }
}
